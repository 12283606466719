<template>
  <div class="top-nav">
    <el-row :gutter="10" justify="space-between">
      <el-col :xs="{span:4, push:1}" :sm="{span:4, push:1}" :md="{span:4, push:1}" :lg="{span:4, push:1}" :xl="{span:4, push:1}">
        <div class="top-logo">
          <el-image class="top-logo-image" :fit="'fill'" :src="logoUrl" alt="homapp_logo" />
          <span class="top-logo-title">&nbsp;HOMAPP</span>
        </div>
      </el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="8" :xl="8">
        <div></div>
      </el-col>
      <el-col :xs="{span:4, pull:2}" :sm="{span:4, pull:2}" :md="{span:4, pull:2}" :lg="{span:12, push:1}" :xl="{span:12, push:1}">
        <div class="top-menu">
          <div v-if="isLargeScreen">
            <el-menu mode="horizontal" :default-active="activeIndex" @select="handleSelect">
              <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item>
              <el-menu-item index="2"><span class="top-menu-item">{{menu[1]}}</span></el-menu-item>
              <el-menu-item index="3"><span class="top-menu-item">{{menu[2]}}</span></el-menu-item>
              <el-menu-item index="4"><span class="top-menu-item">{{menu[3]}}</span></el-menu-item>
              <el-menu-item index="5"><span class="top-menu-item">{{menu[4]}}</span></el-menu-item>
              <el-menu-item index="6">
                <el-switch 
                  v-model="switchValue" 
                  inline-prompt
                  active-text="EN"
                  inactive-text="中" 
                  size="large" 
                  style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                />
              </el-menu-item>
            </el-menu>
          </div>
          <div v-else>
            <el-button @click="handleOpen"><el-icon><Fold /></el-icon></el-button>
            <el-drawer v-model="isOpenDrawer" :show-close="false" :append-to-body="true" size="60%" :before-close="handleClose">
              <el-menu mode="vertical" :default-active="activeIndex" @select="handleSelect">
              <el-menu-item index="1"><span class="top-menu-item">{{menu[0]}}</span></el-menu-item>
              <el-menu-item index="2"><span class="top-menu-item">{{menu[1]}}</span></el-menu-item>
              <el-menu-item index="3"><span class="top-menu-item">{{menu[2]}}</span></el-menu-item>
              <el-menu-item index="4"><span class="top-menu-item">{{menu[3]}}</span></el-menu-item>
              <el-menu-item index="5"><span class="top-menu-item">{{menu[4]}}</span></el-menu-item>
              <el-menu-item index="6">
                <el-switch 
                  v-model="switchValue" 
                  inline-prompt
                  active-text="EN"
                  inactive-text="中" 
                  size="large" 
                  style="--el-switch-on-color: #3D80B4; --el-switch-off-color: #ff4949; padding-top: 8px;"
                />
              </el-menu-item>
            </el-menu>
            </el-drawer>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { Fold } from '@element-plus/icons-vue'
import { Store } from '@/utils/store'
import { Lang } from '@/utils/language'
export default {
  name: 'TopNav',
  components: { Fold },
  props: {
    logo: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLargeScreen: Store.largeScreen,
      isOpenDrawer: false,
      activeIndex: '1',
      switchValue: true,
      logoUrl: this.logo,
    }
  },
  computed: {
    menu() {
      if (this.switchValue) {
        Store.changeLang('en');
        return Lang.Menu.en;
      } else {
        Store.changeLang('cn');
        return Lang.Menu.cn;
      }
    },
    buyPageUrl() {
      return Store.metropolitan.urlBuy;
    },
    rentPageUrl() {
      return 'https://rental.homapp.com';
    }
  },
  methods: {
    handleOpen(){
        this.isOpenDrawer = true;
    },
    handleClose(){
        this.isOpenDrawer = false;
    },    
    handleSelect(idx) {
      switch(idx) {
        case '1':
          this.$router.push({ name: 'home' });
          break;
        case '2':
          window.open(this.buyPageUrl, '_blank');
          break;
        case '3':
          window.open(this.rentPageUrl, '_blank');
          break;
        case '4':
          this.$router.push({ name: 'metropolitan' });
          break;
        case '5':
          this.$router.push({ name: 'about' });
          break;
        case '6':
        default:
          this.activeIndex = Store.preIndex;
      }
      this.handleClose();
    }
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.top-nav {
  z-index: 1;
  width: 100%;
  height: $top-height;
  border-bottom: 1px solid rgba(144, 147, 153, 0.2);
  background-color: white;
}
.top-logo {
  padding-top: 15px;
  text-align: left;
}
.top-logo-image {
  width: 40px; 
  height: 40px;
  vertical-align: middle;
}
@media screen and (min-width: 1201px) {
.top-logo-title {
  font-size: 2em;
  font-weight: 700;
  color: $theme-color;
  vertical-align: middle;
}
}
@media screen and (max-width: 1200px) {
.top-logo-title {
  display: none;
}
}
@media screen and (min-width: 1201px) {
.top-metro-label {
  padding-top: 20px;
  font-size: 0.6em;
  font-weight: 300;
  color: black;
  text-align: right;
}
}
@media screen and (max-width: 1200px) {
.top-metro-label {
  display: none;
}
}
.top-menu {
  padding-top: 15px;
  text-align: right;
}
.top-menu-item {
  font-size: 1.3em;
}
</style>
