const Menu = {
  en: ['Home', 'Buy', 'Rent', 'Metropolitan', 'About'],
  cn: ['主页', '买房', '租房', '大都会', '关于'],
};
const Home = {
  en: ['consists of the following cities:', 'A total of', 'properties for buy', 'properties for rent', 'Canadian Real Estate News', 'Home/News', 'Source link:'],
  cn: ['由以下城市组成：', '总计', '套卖出房源', '套出租房源', '加拿大地产信息', '主页/新闻', '来源链接：'],
};
const Metropolitan = {
  en: ['Please choose Metropolitan of your location'],
  cn: ['请选择您所在地的大都会'],
};
const AboutUs = {
  en: [
    "HOMAPP.COM is the website version of the HOMAPP mobile app, which provides you with detailed information on properties for sale and rent in Canada's major metropolitan areas, and recommends licensed real estate agents for you.", 
    'HOMAPP.COM only provides technical services for recommended real estate agents, does not participate in any real estate transaction related services of real estate agents, and is not responsible for any real estate transaction process and results of real estate agents.', 
    'If you have any technical questions, please contact us by e-mail: info@homapp.com'
  ],
  cn: [
    'HOMAPP.COM是地产知道APP的网站版本，为您提供详尽的加拿大的主要大都会区在售和在租的房产信息，并为您推荐持牌的房产经纪人。', 
    'HOMAPP.COM只为推荐的房产经纪人提供技术服务，不参与房产经纪人的任何房产交易相关服务，也不对房产经纪人的任何房产交易过程和结果负责。', 
    '您有任何技术问题请通过电子信箱联系我们：info@homapp.com'
  ],
};
const Ads = {
  en: [
    'Since 2015, HOMAPP is the first of a mobile app for Chinese real estate in Canada, and has now expanded the language categories to 5 to express respect for the multiculturalism of Canada.', 
    'Please download!'
  ],
  cn: [
    '自2015年，HOMAPP第一个在加拿大推出了中文地产应用，现已将语言种类扩展到了5个，以表达对多加拿大多元文化的敬意。',
    '敬请下载！'
  ],
};


export const Lang = {
  Menu,
  Home,
  Metropolitan,
  AboutUs,
  Ads,
}