<template>
  <div v-if="tag">
    <router-view />
  </div>
  <div v-else>
    <top-nav :logo="logoUrl" />
    <router-view />
    <bottom-claim :logo="logoCompanyUrl" />
  </div>
</template>


<script>
import { Store } from '@/utils/store'
import { getMetroList } from '@/utils/api'
import TopNav from '@/components/TopNav'
import BottomClaim from '@/components/BottomClaim'
export default {
  components: { TopNav, BottomClaim },
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
      logoCompanyUrl: require('@/assets/fairycow-logo.png'),
      tag: false
    }
  },
  created() {
    // 设置CSS变量的值
    document.documentElement.style.setProperty('--width', window.innerWidth + 'px');
    document.documentElement.style.setProperty('--height', window.innerHeight + 'px');

    getMetroList().then(res => {
      Store.changeMetroList(res.data);
    }).catch(err => {
      console.log('Not get metro data', err);
    })
  },
  mounted() {
    const pathname = window.location.pathname;
    const regex = /^\/news\/\d{1,7}\/.+$/;
    this.tag = regex.test(pathname);
  }
}
</script>


<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
:root { // 全局变量
  --width: 0;
  --height: 0;
}
</style>
