const HmcUid = process.env.VUE_APP_HMC_UID;
const HmcToken = process.env.VUE_APP_HMC_TOKEN;
const HmcSecret = process.env.VUE_APP_HMC_SECRET;
const HmcApiUrl = process.env.VUE_APP_HMC_URL;
const HomApiUrl = process.env.VUE_APP_HOM_URL;
const HomApiVer = '3.0.0w';
const PageSize = 20;
const IosApp = 'https://apps.apple.com/app/id879209391';
const AndroidApp = 'https://play.google.com/store/apps/details?id=ca.city365.homapp';
/*
const MetroList = [
  {
    logo: require('@/assets/Toronto-of-City.png'),
    name: 'Greater Toronto',
    cities: 'Toronto',
    url: 'https://toronto.homapp.com',
  },
  {
    logo: require('@/assets/Vancouver-of-City.png'),
    name: 'Greater Vancouver',
    cities: 'Vancouver',
    url: 'https://vancouver.homapp.com',
  },
  {
    logo: require('@/assets/Montreal-of-City.png'),
    name: 'Greater Montreal',
    cities: 'Montreal',
    url: 'https://montreal.homapp.com',
  },
  {
    logo: require('@/assets/Calgary-of-City.png'),
    name: 'Greater Calgary',
    cities: 'Calgary',
    url: 'https://calgary.homapp.com',
  },
  {
    logo: require('@/assets/Ottawa-of-City.png'),
    name: 'Ottawa Gatineau',
    cities: 'Ottawa',
    url: 'https://ottawa.homapp.com',
  },
  {
    logo: require('@/assets/Edmonton-of-City.png'),
    name: 'Edmonton Region',
    cities: 'Edmonton',
    url: 'https://edmonton.homapp.com',
  },
  {
    logo: require('@/assets/Victoria-of-City.png'),
    name: 'Greater Victoria',
    cities: 'Victoria',
    url: 'https://victoria.homapp.com',
  },
  {
    logo: require('@/assets/Québec-of-City.png'),
    name: 'Québec Region',
    cities: 'Québec',
    url: 'https://quebec.homapp.com',
  },
];
*/

export const Consts = {
  HmcUid,
  HmcToken,
  HmcSecret,
  HmcApiUrl,
  HomApiUrl,
  HomApiVer,
  PageSize,
  IosApp,
  AndroidApp,
  // MetroList,
} 
