import { request } from '@/utils/request'
import requestEx from '@/utils/requestEx'

export function getMetroList() {
  return request({
    method: 'GET',
    api: 'metro_area',
    data: ''
  })
}

export function getSaleList(metro) {
  return request({
    method: 'GET',
    api: 'property_list',
    data: 'metro_area=' + metro
  })
}

export function getLeaseList(metro) {
  return request({
    method: 'GET',
    api: 'rental_list',
    data: 'metro_area=' + metro
  })
}

export function getNewsList(params = {}) {
  return requestEx({
    url: 'news.list',
    method: 'POST',
    data: params
  })
}

export function getNewsDetail(params = {}) {
  return requestEx({
    url: 'news.detail',
    method: 'POST',
    data: params
  });
}
