import { Consts } from '@/utils/consts'

export function request({ method, api, data = {} }) {
  return new Promise((resolve, reject) => {
    let url = Consts.HomApiUrl + `${api}/?${data}` + `&app_version=` + Consts.HomApiVer;
    let options = { 
      method: 'GET',
      // mode: 'no-cors',    // no-cors, cors, *same-origin
      // credentials: 'omit', // include, same-origin, *omit
      // redirect: 'follow', // manual, *follow, error
      // headers: { "Accept": "application/json" }
      // headers: {'Content-Type': 'text/plain'};
    };
    
    fetch(url, options)
    // .then(res => res.text()) 
    .then(res => res.json()) 
    .then(data => {
      // console.log('data:', data);
      if (data.success == 'Y') {
        return resolve(data);
      } else {
        return reject('data return failed');
      }
    })
    .catch(err => {
      console.log('err:', err);
      return reject(err);
    })
  })
}
