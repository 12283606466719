<template>
  <div class="home">
    <el-row :gutter="0">
      <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16" class="home-info">
          <div class="header">
            <h1>{{titles[4]}}</h1>
          </div>
          <div class="list" v-for="(item, idx) in list" :key="item">
            <news-item :rowid="idx" :itemData="item" @on-click="handleDetail" />
          </div>
          <pagination v-show="listTotal>0" :total="listTotal" :page.sync="listQuery.page" :limit.sync="listQuery.size" @pagination="getList" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="home-mobile">
        <div class="top-metro-logo">
          <el-image style="width:90px; height:36px;" :fit="'fill'" :src="metroUrl" alt="metro_logo" />
        </div>

        <div v-if="isLargeScreen">
          <el-image style="width:80%; height:80%;" fit="fill" :src="metroPicture" alt="city_pic" />
        </div>
        <div v-else>
          <el-image style="width:90%; height:90%;" fit="fill" :src="metroPicture" alt="city_pic" />
        </div>

        <div class="home-metro-cities">
          <h4>
            {{metroName}} {{titles[0]}}
            <el-link type="primary" :onclick="handleMetro">Other metropolitan</el-link>
          </h4>
          <p>{{metroCities}}</p>
        </div>
        <div>
          <el-button size="large" type="primary" plain @click="handleSale">{{saleProperties}} {{titles[2]}}</el-button>
          <el-button size="large" type="primary" plain @click="handleLease">{{leaseProperties}} {{titles[3]}}</el-button>
        </div>

        <br>
        <br>
        <el-affix :offset="100">
          <el-link :href="videoUrl" target="_blank" :onclick="googleAnalytics">
            <div class="home-mobile-image">
              <div>
                <el-image style="width:150px; height:45px;" :fit="'fill'" :src="homappUrl" alt="homapp_logo" />
              </div>
              <div>
                <el-image style="width:40%; height:40%;" fit="contain" :src="mobileUrl" alt="mobile_bg" />
              </div>
            </div>
          </el-link>
          <br>
          <div>
            <el-link :href="iosUrl" target="_blank" :onclick="googleAnalytics">
              <el-image style="width:124px; height:40px;" fit="fill" :src="appStoreUrl" alt="app_store_logo" />
            </el-link>
            <el-link :href="androidUrl" target="_blank" :onclick="googleAnalytics">
              <el-image style="width:124px; height:40px;" fit="fill" :src="googlePlayUrl" alt="google_play_logo" />
            </el-link>
          </div>
          <br>
        </el-affix>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { Store } from '@/utils/store'
import { Lang } from '@/utils/language'
import { Consts } from '@/utils/consts'
import { getNewsList, getSaleList, getLeaseList } from '@/utils/api'
import NewsItem from '@/components/NewsItem'
import Pagination from '@/components/Pagination'
export default {
  name: 'HomeView',
  components: { NewsItem, Pagination },
  data() {
    return {
      isLargeScreen: Store.largeScreen,

      // cityUrl: require('@/assets/city/Vancouver.jpg'),
      homappUrl: require('@/assets/app/homapp-logo.png'),
      mobileUrl: require('@/assets/app/mobile-bg.png'),
      appStoreUrl: require('@/assets/app/app-store-logo.png'),
      googlePlayUrl: require('@/assets/app/google-play-logo.png'),
      videoUrl: 'https://www.youtube.com/watch?v=96fIY1cFJu0?autoplay=1',
      iosUrl: Consts.IosApp,
      androidUrl: Consts.AndroidApp,
      saleProperties: 0,
      leaseProperties: 0,

      list: [],
      listTotal: 0,
      listQuery: {
        page: 1,
        from: 0,
        size: Consts.PageSize
      },
    }
  },
  mounted() {
    const metro = localStorage.getItem('metropolitan')
    if (metro !== null) {
      Store.changeMetro(JSON.parse(metro));
    }
    this.getTotal();
    this.handleNews();
  },
  computed: {
    metroUrl() {
      return Store.metropolitan.logo;
    },
    metroName() {
      this.getTotal();
      return Store.metropolitan.name;
    },
    metroPicture() {
      return Store.metropolitan.picture;
    },
    metroCities() {
      return Store.metropolitan.cities;
    },
    titles() {
      if (Store.lang == 'en') {
        return Lang.Home.en;
      } else {
        return Lang.Home.cn;
      }
    },
    salePageUrl() {
      return Store.metropolitan.urlBuy;
    },
    leasePageUrl() {
      return 'https://rental.homapp.com';
    },
  },
  methods: {
    async getTotal() {
      const resSale = await getSaleList(Store.metropolitan.name);
      this.saleProperties = resSale.results_found;
      const resLease = await getLeaseList(Store.metropolitan.name);
      this.leaseProperties = resLease.results_found;
    },
    async getList(query) {
      this.listQuery.page = query.page;
      this.listQuery.size = query.limit;
      this.listQuery.from = (this.listQuery.page-1) * this.listQuery.size;
      const response = await getNewsList(this.listQuery);
      this.list = response.list;
      this.listTotal = response.total;
    },
    handleMetro() {
      this.$router.push({ name: 'metropolitan', });
    },
    handleSale() {
      window.open(this.salePageUrl, '_blank');
    },
    handleLease() {
      window.open(this.leasePageUrl, '_blank');
    },
    handleNews() {
      this.listQuery.from = (this.listQuery.page-1) * (this.listQuery.size-1);
      getNewsList(this.listQuery).then(res => {
        this.list = res.list;
        this.listTotal = res.total;
      }).catch(err => {
        this.list = [];
        this.listTotal = 0;
      })
    },
    handleDetail(idx) {
      this.$router.push({
        name: 'news detail',
        params: {newsid: this.list[idx].newsid}
      })
    },
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';
.home {
  width: 100%;
}
.home-info {
  padding: 20px 0;
  text-align: center;
}
.home-metro-cities {
  padding: 0 10%;
  text-align: left;
}
.home-news {
  padding-top: 20px;
  text-align: center;
}
.home-mobile {
  padding-top: 20px;
  text-align: center;
}
@media screen and (min-width: 1201px) {
.home {
  min-height: calc(var(--height) * 0.74);
}
.home-mobile-image {
  display: block;
}
}
@media screen and (max-width: 1200px) {
.home-mobile-image {
  display: none;
}
}
</style>