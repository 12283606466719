<template>
  <div class="bottom">
    <el-row :gutter="20">
      <el-col :xs="{span:8, push:2}" :sm="{span:8, push:2}" :md="{span:8, push:2}" :lg="{span:2, push:1}" :xl="{span:2, push:1}">
        <div class="bottom-logo">
          <a href="https://www.fairycow.ca" alt="Developed by: FairyCOW.ca" target="_blank"> <el-avatar fit="fill" :size="logoSize" :src="logoUrl" alt="fairycow_logo" /> </a>
        </div>
      </el-col>
      <el-col :xs="16" :sm="16" :md="16" :lg="6" :xl="6">
        <div class="bottom-info">
          HOMAPP Website developed by FairyCOW Software Inc., who is qualified DDF<sup>®</sup> Technology Provider by CREA
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="{span:16, pull:1}" :xl="{span:16, pull:1}">
        <div class="bottom-claim">
          <a href="https://www.realtor.ca/en" alt="Powered by: REALTOR.ca" target="_blank"> <img width="90" height="40" src="https://www.realtor.ca/images/en-ca/powered_by_realtor.svg" alt="crea_logo" /></a>
          The trademarks REALTOR<sup>®</sup>, REALTORS<sup>®</sup> and the REALTOR<sup>®</sup> logo are controlled by The Canadian Real Estate Association (CREA) and identify real estate professionals who are members of CREA.
          The trademarks MLS<sup>®</sup>, Multiple Listing Service<sup>®</sup> and the associated logos are owned by CREA and identify the quality of services provided by real estate professionals who are members of CREA.
          The trademark DDF<sup>®</sup> is also owned by CREA and identifies the REALTOR.ca Data Distribution Facility (DDF<sup>®</sup>).
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import { Store } from '@/utils/store'
export default {
  name: 'BottomClaim',
  props: {
    logo: {
      reuired: true,
      type: String
    }
  },
  data() {
    return {
      logoUrl: this.logo,
    }
  },
  computed: {
    logoSize() {
      return Store.largeScreen ? 70 : 50;
    },
  }
}
</script>


<style lang="scss">
@import '@/assets/variables.scss';
.bottom {
  // padding: 20px 100px;
  z-index: 1;
  background-color: $theme-color;
  color: white;
  font-size: 1em;
  text-align: left;
}

/* 设置了浏览器宽度不小于1201px时 list显示的宽度 */
@media screen and (min-width: 1201px) {
.bottom-logo {
  padding: 35px 0;
  vertical-align: middle;
}
.bottom-info {
  padding: 50px 0 0 20px;
  font-size: 1em;
}
.bottom-claim {
  padding: 20px 50px;
  font-size: 1em;
}
}
@media screen and (max-width: 1200px) {
.bottom-logo {
  padding: 20px 0 0 0;
  vertical-align: middle;
}
.bottom-info {
  padding: 20px 0 0 0;
  font-size: 0.3em;
}
.bottom-claim {
  padding: 10px 10px;
  font-size: 0.3em;
}
}
</style>