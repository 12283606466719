import { createApp } from 'vue'
import App from './App.vue'
// import store from './store'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App)
// .use(store)
.use(router)
.use(ElementPlus)
.mount('#app')

import { Store } from './utils/store'
window.onresize = function() {
  Store.changeScreen();
}

// 注入 Vuex Store
// app.config.globalProperties.$store = store;

// 使用 onMounted 钩子来监听窗口大小变化
// import { onMounted } from 'vue'
// onMounted(() => {
//   window.onresize = function() {
//     app.config.globalProperties.$store.commit('changeScreen');
//   };
// });