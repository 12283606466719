/*
  Request to Homapp-Manage-Console-Backend API
*/
import axios from 'axios'
import store from '@/store'
import md5 from '@/utils/md5'
// import { authDB, getToken } from '@/utils/auth'
import { Consts } from '@/utils/consts'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
const uid = Consts.HmcUid;
const token = Consts.HmcToken;
const secret = Consts.HmcSecret;


function renderURL(method, data = {}) {
  // const isSign = method.indexOf('auth.') > -1
  // const uid = isSign ? '' : authDB.get('uid');
  // const token = isSign ? '' : getToken();
  const version = '1.0'
  const mtime = parseInt(Date.now() / 1000);
  const body = JSON.stringify(data);
  const sign = md5('hmc' + version + token + body + method + mtime + secret);
  return {
    url: `${method}?t=${mtime}&u=${uid}&v=${version}&s=${sign}`,
    method: 'post',
    data: body
  }
}
  
// create an axios instance
const service = axios.create({
  baseURL: Consts.HmcApiUrl,                // url = base url + request url
  timeout: 30000                            // request timeout
  // withCredentials: true,                 // send cookies when cross-domain requests
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['X-Token'] = getToken()
      config.headers['X-Token'] = token
    }

    const { url = '' } = config
    let { data } = config
    if (data && typeof data === 'string') {
      data = JSON.parse(data)
    }

    const obj = renderURL(url, data)
    return Object.assign({}, config, obj)
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      ElNotification({
        title: 'Error',
        message: res.msg || 'Error',
        type: 'error',
        duration: 2000
      })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          store.dispatch('resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if(res.msg != '' && res.msg != '成功' && res.msg != 'success' && res.msg != 'Success'){
        ElNotification({
          title: 'Info',
          message: res.msg || 'Duplicate name',
          type: 'info',
          duration: 3000
        })
      }
      return res.data
    }
  },
  error => {
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
