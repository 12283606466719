import { reactive } from 'vue'

export const Store = reactive({
  lang: 'en',
  changeLang(val) { 
    this.lang = val;
  },

  preIndex: '1',
  changePreIndex(val) {
    this.preIndex = val;
  },

  metropolitan: {
    logo: require('@/assets/city/Vancouver-logo.png'),
    picture: require('@/assets/city/Vancouver.jpg'),
    name: 'Greater Vancouver',
    cities: 'Anmore, Belcarra, Bowen Island, Burnaby, Coquitlam, Delta, Langley, Lions Bay, Maple Ridge, New Westminster, North Vancouver, Pitt Meadows, Port Coquitlam, Port Moody, Richmond, Surrey, Tsawwassen, Vancouver, West Vancouver, White Rock',
    urlBuy: 'https://vancouver.homapp.com',
    urlRent: 'https://rental.homapp.com',
  },
  changeMetro(val) { 
    this.metropolitan = Object.assign({}, val);
    localStorage.setItem('metropolitan', JSON.stringify(this.metropolitan));
    // console.log('Metro:', this.metropolitan)
  },

  metroList: [],
  changeMetroList(val) { 
    for (let key in val) {
      const metroName = key;
      const cityName = metroName.replace('Greater ', '').replace(' Region', '').replace('–Gatineau', '');
      const str = JSON.stringify(val[key]).replaceAll(metroName, '').replace(/,"metro_area":/g, '').replace(/"city":/g, '').replace(/{|}|"|\[|\]/g, '');
      const metro = {
        logo: require(`@/assets/city/${cityName}-logo.png`),
        picture: require(`@/assets/city/${cityName}.jpg`),
        name: metroName,
        cities: str.replaceAll(',', ', '),
        urlBuy: `https://${cityName}.homapp.com`,
        urlRent: `https://rental.homapp.com`,
        // urlRent: `https://rental.homapp.com/${cityName}`,
      };
      this.metroList.push(metro);
    }
  },

  largeScreen: window.innerWidth >= 1200 ? true : false,
  changeScreen() {
    if (window.innerWidth < 1200) {
      this.largeScreen = false;
    } else {
      this.largeScreen = true;
    }
  },
})